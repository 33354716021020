import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutModernPageComponent } from './components/pages/about-modern-page/about-modern-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { HomeDemoEightComponent } from './components/pages/home-demo-eight/home-demo-eight.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { FixAndFlipProductPageComponent } from './components/pages/fix-and-flip-product-page/fix-and-flip-product-page.component';
import { LongTermRentalProductPageComponent } from './components/pages/long-term-rental-product-page/long-term-rental-product-page.component';
import { ShortTermBridgeProductPageComponent } from './components/pages/short-term-bridge-product-page/short-term-bridge-product-page.component';
import { GroundUpConstructionProductPageComponent } from './components/pages/ground-up-construction-product-page/ground-up-construction-product-page.component';
import { QuestionExitStrategyComponent } from './components/pages/question-exit-strategy/question-exit-strategy.component';
import { QuestionFlipExperienceComponent } from './components/pages/question-flip-experience/question-flip-experience.component';
import { QuestionRentalExperienceComponent } from './components/pages/question-rental-experience/question-rental-experience.component';
import { QuestionEstimatedFICOComponent } from './components/pages/question-estimated-fico/question-estimated-fico.component';
import { QuestionNameComponent } from './components/pages/question-name/question-name.component';
import { QuestionPhoneComponent } from './components/pages/question-phone/question-phone.component';
import { QuestionEmailComponent } from './components/pages/question-email/question-email.component';
import { QuestionPropertyAddressComponent } from './components/pages/question-property-address/question-property-address.component';
import { QuestionSubjectPropertyTypeComponent } from './components/pages/question-subject-property-type/question-subject-property-type.component';
import { QuestionSubjectPropertyCostComponent } from './components/pages/question-subject-property-cost/question-subject-property-cost.component';
import { QuestionWhatsNextComponent } from './components/pages/question-whats-next/question-whats-next.component';
import { DecemberTexasHardMoneyLoansComponent } from './components/pages/Blogs/december-texas-hard-money-loans/december-texas-hard-money-loans.component';
import { BlogMainComponent } from './components/pages/Blogs/blog-main/blog-main.component';
import { DecemberInvestingInFloridaComponent } from './components/pages/Blogs/december-investing-in-florida/december-investing-in-florida.component';
import { DecemberMinimalDownPaymentComponent } from './components/pages/Blogs/december-minimal-down-payment/december-minimal-down-payment.component';
import { DecemberGroundUpConstructionComponent } from './components/pages/Blogs/december-ground-up-construction/december-ground-up-construction.component';
import { JulyNextBigThingComponent } from './components/pages/Blogs/july-next-big-thing/july-next-big-thing.component';
import { JulyTopTenZipsComponent } from './components/pages/Blogs/july-top-ten-zips/july-top-ten-zips.component';
import { HardMoneyLoansNorthCarolinaPageComponent } from './components/pages/hard-money-loans-north-carolina-page/hard-money-loans-north-carolina-page.component';
import { HardMoneyLoansArizonaPageComponent } from './components/pages/hard-money-loans-arizona-page/hard-money-loans-arizona-page.component';
import { HardMoneyLoansFloridaPageComponent } from './components/pages/hard-money-loans-florida-page/hard-money-loans-florida-page.component';
import { HardMoneyLoansTexasPageComponent } from './components/pages/hard-money-loans-texas-page/hard-money-loans-texas-page.component';
import { HardMoneyLoansCaliforniaPageComponent } from './components/pages/hard-money-loans-california-page/hard-money-loans-california-page.component';
import { HardMoneyLoansTennesseePageComponent } from './components/pages/hard-money-loans-tennessee-page/hard-money-loans-tennessee-page.component';
import { HardMoneyLoansGeorgiaPageComponent } from './components/pages/hard-money-loans-georgia-page/hard-money-loans-georgia-page.component';
import { HardMoneyLoansSanDiegoPageComponent } from './components/pages/hard-money-loans-san-diego-page/hard-money-loans-san-diego-page.component';
import { HardMoneyLoansLosAngelesPageComponent } from './components/pages/hard-money-loans-los-angeles-page/hard-money-loans-los-angeles-page.component';
import { HardMoneyLoansNashvillePageComponent } from './components/pages/hard-money-loans-nashville-page/hard-money-loans-nashville-page.component';

const routes: Routes = [
    {path: '', component: HomeDemoEightComponent},
    {
        path: 'contact', 
        component: ContactPageComponent,
        title: 'Nvestor Funding: Contact Us for Any Questions'
    },
    { 
        path: 'fix-and-flip-loans', 
        component: FixAndFlipProductPageComponent,
        title: 'Nvestor Funding: Your Source for Fix and Flip Loans'
    },
    { 
        path: 'long-term-rental-loans', 
        component: LongTermRentalProductPageComponent,
        title: 'Nvestor Funding: Your Source for Long Term Rental Loans'
    },
    { 
        path: 'short-term-bridge-loans', 
        component: ShortTermBridgeProductPageComponent,
        title: 'Nvestor Funding: Your Source for Short Term Bridge Loans'
    },
    { 
        path: 'ground-up-construction-loans', 
        component: GroundUpConstructionProductPageComponent,
        title: 'Nvestor Funding: Your Source for Ground Up Construction Loans'
    },
    { 
        path: 'about-us', 
        component: AboutModernPageComponent,
        title: 'Nvestor Funding: About Us'
    },
    { 
        path: 'blog', 
        component: BlogMainComponent,
        title: 'Nvestor Funding: Visit Our Blog'
    },
    { path: 'exit-strategy', component: QuestionExitStrategyComponent },
    { path: 'completed-fix-and-flips', component: QuestionFlipExperienceComponent },
    { path: 'owned-long-term-rentals', component: QuestionRentalExperienceComponent },
    { path: 'estimated-fico-score', component: QuestionEstimatedFICOComponent },
    { path: 'whats-your-name', component: QuestionNameComponent },
    { path: 'whats-your-phone-number', component: QuestionPhoneComponent },
    { path: 'whats-your-email-address', component: QuestionEmailComponent },
    { path: 'subject-property-address', component: QuestionPropertyAddressComponent },
    { path: 'subject-property-type', component: QuestionSubjectPropertyTypeComponent },
    { path: 'are-we-refinancing', component: QuestionSubjectPropertyCostComponent },
    { path: 'whats-next', component: QuestionWhatsNextComponent },
    { 
        path: 'texas-hard-money-loans-a-faster-path-to-real-estate-success-for-investors-and-flippers', 
        component: DecemberTexasHardMoneyLoansComponent,
        title: 'Nvestor Funding: A Faster Path to Real Estate Success for Investors and Flippers'
    },
    { 
        path: 'investing-in-florida-real-estate-discover-the-benefits-of-hard-money-loanssave', 
        component: DecemberInvestingInFloridaComponent,
        title: 'Nvestor Funding: Investing in Florida Real Estate Discover the Benefits of Hard Money Loans'
    },
    { 
        path: 'unlocking-opportunities-a-guide-to-buying-an-investment-property-with-minimal-down-payment', 
        component: DecemberMinimalDownPaymentComponent,
        title: 'Nvestor Funding: Unlocking Opportunities A Guide To Buying An Investment Property With Minimal Down Payment'
    },
    {
        path: 'the-ultimate-guide-to-ground-up-construction-loans-everything-you-need-to-know', 
        component: DecemberGroundUpConstructionComponent,
        title: 'Nvestor Funding: The Ultimate Guide to Ground Up Construction Loans' 
    },
    { 
        path: 'student-housing-the-next-big-thing-in-cre', 
        component: JulyNextBigThingComponent,
        title: 'Nvestor Funding: Student Housing The Next Big Thing in CRE'
    },
    { 
        path: 'are-you-next-the-10-zip-codes-where-foreclosures-are-on-the-rise', 
        component: JulyTopTenZipsComponent,
        title: 'Nvestor Funding: Are You The Next Top 10 Zip Codes Where Foreclosures Are On The Rise'
    },
    {   
        path: 'hard-money-loans-north-carolina', 
        component: HardMoneyLoansNorthCarolinaPageComponent,
        title: 'Hard Money Loans in North Carolina - Fast Cash for Fixers & Investors | Nvestor Funding'
    },
    { 
        path: 'hard-money-loans-arizona', 
        component: HardMoneyLoansArizonaPageComponent,
        title: 'Hard Money Loans Arizona: Fuel Your Real Estate Investments with Nvestor Funding | Nvestor Funding'
    },
    {   
        path: 'hard-money-loans-florida', 
        component: HardMoneyLoansFloridaPageComponent,
        title: 'Hard Money Loans in Florida - Fast Cash for Fixers & Investors | Nvestor Funding'
    },
    {   
        path: 'hard-money-loans-texas', 
        component: HardMoneyLoansTexasPageComponent,
        title: 'Texas Hard Money Loans: Get the Funding You Need for Your Real Estate Projects | Nvestor Funding'
    },
    {   
        path: 'hard-money-loans-california', 
        component: HardMoneyLoansCaliforniaPageComponent,
        title: 'Hard Money Loans in California - Fast Cash for Fixers & Investors | Nvestor Funding'
    },
    {   
        path: 'hard-money-loans-san-diego', 
        component: HardMoneyLoansSanDiegoPageComponent,
        title: 'Hard Money Loans in San Diego - Fast Cash for Fixers & Investors | Nvestor Funding'
    },
    {   
        path: 'hard-money-loans-los-angeles', 
        component: HardMoneyLoansLosAngelesPageComponent,
        title: 'Hard Money Loans in Los Angeles - Fast Cash for Fixers & Investors | Nvestor Funding'
    },
    {   
        path: 'hard-money-loans-tennessee', 
        component: HardMoneyLoansTennesseePageComponent,
        title: 'Hard Money Loans in Tennessee - Fast Cash for Fixers & Investors | Nvestor Funding'
    },
    {   
        path: 'hard-money-loans-georgia', 
        component: HardMoneyLoansGeorgiaPageComponent,
        title: `Unlock Georgia's Real Estate with Hard Money Loans: Your Complete Guide | Nvestor Funding`
    },
    {   
        path: 'hard-money-loans-nashville', 
        component: HardMoneyLoansNashvillePageComponent,
        title: 'Hard Money Loans in Nashville - Fast Cash for Fixers & Investors | Nvestor Funding'
    },
    {   
        path: '**', 
        component: NotFoundComponent
    } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }