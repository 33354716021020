<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="container questions-width">
        <h1 class="headline">What's your email address?</h1>
        <div class="row">
            <form class="multiple-box"> 
                <input                        
                       class="form-control haus-form-control"
                       type="text" 
                       placeholder="Email Address"
                       [(ngModel)]="this.hubspot.QuestionEmailAddress" 
                       [ngModelOptions]="{standalone: true}" 
                       maxlength="25" 
                       required >
            </form>
        </div>
        <h5 class="haus-answer-h5" *ngIf="this.hubspot.QuestionEmailAddress">Your Email Address: <span style="color: #F66D0E;">{{ this.hubspot.QuestionEmailAddress}}</span></h5>
        <a class="haus-btn" *ngIf="this.hubspot.QuestionEmailAddress" routerLink="/whats-your-phone-number">
            <h1>Next</h1>
        </a>
        <h5 class="haus-h5" *ngIf="this.hubspot.QuestionEmailAddress" routerLink="/estimated-fico-score">Go Back</h5>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>