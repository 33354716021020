<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>Ground Up Construction Loans</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Ground Up Construction Loans</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="about-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="mt-5 row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <span class="sub-title">NEW CONSTRUCTION</span>
                        <h2><a href="https://blog.nvestorfunding.com/blog/ground-up-construction" target="_blank">The Benefits of Ground Up Construction Loans</a></h2>
                        <p>One of the primary benefits of ground up construction loans is the ability to customize your home. Whether you have a specific architectural style in mind or unique design preferences, a construction loan allows you to build a home that reflects your personal taste and lifestyle. From choosing the layout and finishes to incorporating energy-efficient features, the possibilities are endless.
                        </p>
                        <div class="features-text">
                            <h6>Loan Amounts:</h6>
                            <p>Up to $3.5MM</p>
                        </div>
                        <div class="features-text">
                            <h6>Loan to Cost:</h6>
                            <p>Up to 85%</p>
                        </div>
                        <div class="features-text">
                            <h6>Loan Terms:</h6>
                            <p>Up to 18 Months</p>
                        </div>
                        <div class="features-text">
                            <h6>Property Types:</h6>
                            <p>SFR(1-4)</p>
                        </div>
                        <div class="btn-box">
                            <a routerLink="/exit-strategy" class="default-btn">Get A Fast Free Quote</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-image text-center">
                        <img src="../../../../assets/img/products/nvestor-funding-ground-up-construction-loans-2.png" alt="Nvestor Funding Ground Up Construction Loans">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="../../../../assets/img/products/nvestor-funding-ground-up-construction-loans-3.png"
                        data-aos="fade-up" alt="Nvestor Funding Ground Up Construction Loans">
                </div>
            </div>
            <div class="mt-5 col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">GROUND UP</span>
                    <h2>Understanding Ground Up Construction Loans</h2>
                    <p>Ground up construction loans are specialized financing options designed specifically for
                        individuals looking to build a new home from scratch. Unlike traditional mortgages, which are
                        used to purchase existing homes, ground up construction loans provide the funds necessary to
                        cover the costs of land acquisition, construction, permits, and other expenses associated with
                        building a home.
                    </p>
                    <p>
                        One of the key benefits of ground up construction loans is that they offer borrowers more
                        flexibility than traditional mortgages. With a construction loan, you have the ability to
                        customize your home to your exact specifications and build a property that truly meets your
                        needs. Additionally, these loans typically have higher loan-to-value ratios, allowing you to
                        borrow a larger percentage of the total project cost.
                        However, it's important to note that ground up construction loans are more complex than
                        traditional mortgages and require a different set of qualifications and documentation. In the
                        following sections, we'll guide you through the step-by-step process of obtaining a ground up
                        construction loan.
                    </p>
                    <div class="btn-box">
                        <a routerLink="/exit-strategy" class="default-btn">Get A Fast Free Quote</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-ground-up-construction-faq></app-ground-up-construction-faq>
<app-main-just-funded></app-main-just-funded>
<app-testimonials></app-testimonials>
<app-footer-style-five></app-footer-style-five>