<div class="app-about-area pt-100 pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-about-content">
                    <span class="sub-title">FOR GROUND UP PROJECTS</span>
                    <h2>Ground Up Construction Loans</h2>
                    <p>Enjoy the benefits of property appreciation with flexible terms lengths, low rates, and easy processes to maximize monthly rental income.</p>
                    <div class="row">
                        <div class="col">
                            <ul class="list">
                                <li>
                                    <div class="icon bg-3">
                                        <i class="ri-check-line"></i>
                                    </div>
                                    <h3>Property Types:</h3>
                                    <p>SFR(1-4), Multi&Mixed(Up to 8)</p>
                                </li>
                                <li>
                                    <div class="icon bg-3">
                                        <i class="ri-check-line"></i>
                                    </div>
                                    <h3>Loan Amounts:</h3>
                                    <p>$200K - $3.5MM</p>
                                </li>
                            </ul>
                        </div>
                        <div class="col">
                            <ul class="list">
                                <li>
                                    <div class="icon bg-3">
                                        <i class="ri-check-line"></i>
                                    </div>
                                    <h3>Loan to Values:</h3>
                                    <p>Up to 80%</p>
                                </li>
                                <li>
                                    <div class="icon bg-3">
                                        <i class="ri-check-line"></i>
                                    </div>
                                    <h3>Loan Terms:</h3>
                                    <p>Up to 18 Months</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/exit-strategy" class="default-btn">Get A Fast Free Quote</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-about-image">
                    <img src="../../../../../assets/img/products/nvestor-funding-ground-up-construction-loans.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>